<template>
  <div>
    <sticky-top>
      <div class="title">
        <span>{{ isAdd ? '新增商家' : '编辑商家信息' }}</span>
        <span class="back" @click="back"> <i class="iconfont icon-fanhui"></i> 返回 </span>
      </div>
    </sticky-top>

    <div class="wrap">
      <el-row>
        <el-col :xl="10" :lg="10" :md="20" :sm="24" :xs="24">
          <el-form :model="merchant" status-icon ref="form" label-width="100px" @submit.prevent :rules="rules">
            <el-form-item label="商家名称" prop="name">
              <el-input v-model="merchant.name" placeholder="请填写商家名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="商家号" prop="mchnt_cd">
              <el-input v-model="merchant.mchnt_cd" placeholder="请填写商家号" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="merchant.phone" placeholder="请填写联系电话" clearable></el-input>
            </el-form-item>
            <!-- <el-form-item label="商家终端号" prop="pos_tmn">
                            <el-input v-model="merchant.pos_tmn" placeholder="请填写商家终端号" clearable></el-input>
                        </el-form-item> -->
            <el-form-item label="所属行业" prop="category_id">
              <el-select class="full-input" v-model="merchant.category_id" placeholder="请选择所属行业" clearable>
                <el-option v-for="item in cateList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商家地址" prop="address">
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8 }"
                v-model="merchant.address"
                placeholder="请填写商家地址"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="商家经纬度" prop="lngAndLat">
              <div class="flex-align" style="width: 100%">
                <el-input v-model="merchant.lngAndLat" placeholder="请选择商家经纬度" clearable></el-input>
                <el-button @click="openMap">选择经纬度</el-button>
              </div>
              <!-- <el-input v-model="merchant.longitude" placeholder="请填写商家经度" clearable>
                                <template #prepend>经度</template>
                            </el-input> -->
            </el-form-item>
            <!-- <el-form-item prop="latitude">
                            <el-input v-model="merchant.latitude" placeholder="请填写商家纬度" clearable>
                                <template #prepend>纬度</template>
                            </el-input>
                        </el-form-item> -->
            <el-form-item label="营业时间" prop="bus_hours">
              <!-- <el-date-picker v-model="merchant.bus_hours" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                value-format="YYYY-MM-DD"  /> -->
              <el-input v-model="merchant.bus_hours" placeholder="请填写商家营业时间" clearable></el-input>
            </el-form-item>
            <el-form-item label="商家简介" prop="describe">
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8 }"
                placeholder="请输入商家简介"
                v-model="merchant.describe"
                clearable
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="备注" prop="remark">
                            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8 }" placeholder="请输入备注" v-model="merchant.remark" clearable></el-input>
                        </el-form-item> -->
            <el-form-item label="商家logo" prop="logo_img_id">
              <upload-imgs ref="logo_img_id" :value="initLogo" :rules="logoRules" :multiple="false" :maxNum="1" />
            </el-form-item>
            <el-form-item label="商家图片" prop="show_imgs">
              <upload-imgs
                ref="show_imgs"
                :value="initImgs"
                :rules="imgRules"
                :multiple="true"
                :maxNum="5"
                :sortable="true"
              />
              <div>最多五张</div>
            </el-form-item>
            <el-form-item class="submit" v-permission="['编辑商家']">
              <el-button type="primary" @click="submitForm">保 存</el-button>
              <el-button @click="resetForm">重 置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <!-- 地图 -->
      <map-container ref="map" @confirm="addressConfirm"></map-container>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { ref, onMounted, reactive, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import merchantModel from '@/model/merchant'
import { ElMessage } from 'element-plus/lib/components'
import UploadImgs from '@/component/base/upload-image'
import mapContainer from '@/component/base/map'
import util from '@/lin/util/util'

export default {
  components: {
    UploadImgs,
    mapContainer,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const isAdd = ref(false)
    const form = ref(null)
    const cateList = ref([])
    const merchant = ref({})
    const logoRules = reactive({
      maxSize: 2,
    })
    const imgRules = reactive({
      maxSize: 2,
    })
    const initLogo = ref([])
    const initImgs = ref([])
    const drawer = ref(false)
    const ctx = getCurrentInstance()
    // 获取详情
    const getDetail = async id => {
      const res = await merchantModel.getMerchantDetail(id)
      merchant.value = util.deepClone(res)
      merchant.value.lngAndLat = `${merchant.value.longitude}，${merchant.value.latitude}`
      initLogo.value = [
        {
          id: res.logo_img_id,
          display: res.logo_img?.url,
          src: res.logo_img?.url,
          imgId: res.logo_img_id,
        },
      ]
      initImgs.value = res.show_img.map(item => ({
        id: item.img?.id,
        display: item.img?.url,
        src: item.img?.url,
        imgId: item.img?.id,
      }))
      console.log(initLogo, initImgs)
    }

    // 获取所有行业分类
    const getMerCateListAll = async () => {
      const res = await merchantModel.getMerCateListAll()
      cateList.value = res
    }

    // 保存
    const submitForm = () => {
      console.log(merchant.value)
      form.value.validate(async valid => {
        if (valid) {
          const api = isAdd.value ? 'addMerchant' : 'editMerchant'
          const res = await merchantModel[api](merchant.value)
          ElMessage.success(res.message)
          resetForm()
          back()
        } else {
          ElMessage.warning('请将信息补充完整')
        }
      })
    }

    // 定义验证规则
    const getRules = () => {
      const checkInfo = (rule, value, callback) => {
        if (!value || value.length == 0) {
          callback(new Error('请将信息补充完整'))
        }
        callback()
      }
      const checkImg = async (rule, value, callback) => {
        // 获取图片数据
        const val = await ctx.refs[rule.field].getValue()

        let imgArr
        switch (rule.field) {
          case 'show_imgs':
            imgArr = val.map((item, index) => ({
              img_id: item.imgId,
              index,
            }))
            merchant.value[rule.field] = imgArr
            break
          case 'logo_img_id':
            console.log('图片数据', val)
            imgArr = val.map(item => item.imgId)
            merchant.value[rule.field] = imgArr.length ? imgArr.join(',') : null
            break
          default:
            break
        }
        callback()
      }
      const rules = {
        name: [{ validator: checkInfo, trigger: 'blur', required: true }],
        mchnt_cd: [{ validator: checkInfo, trigger: 'blur', required: true }],
        phone: [{ validator: checkInfo, trigger: 'blur', required: true }],
        // pos_tmn: [{ validator: checkInfo, trigger: 'blur', required: true }],
        category_id: [{ validator: checkInfo, trigger: 'blur', required: true }],
        address: [{ validator: checkInfo, trigger: 'blur', required: true }],
        longitude: [{ validator: checkInfo, trigger: 'blur', required: true }],
        latitude: [{ validator: checkInfo, trigger: 'blur', required: true }],
        lngAndLat: [{ validator: checkInfo, trigger: 'blur', required: true }],
        bus_hours: [{ validator: checkInfo, trigger: 'blur', required: true }],
        describe: [{ validator: checkInfo, trigger: 'blur', required: true }],
        // remark: [{ validator: checkInfo, trigger: 'blur', required: true }],
        logo_img_id: [{ validator: checkImg, trigger: 'blur' }],
        show_imgs: [{ validator: checkImg, trigger: 'blur' }],
      }
      return { rules }
    }

    const { rules } = getRules()

    // 选择经纬度
    const openMap = () => {
      ctx.refs.map.initMap(Number(merchant.value.longitude), Number(merchant.value.latitude))
    }

    // 经纬度选择
    const addressConfirm = val => {
      merchant.value.longitude = val.lng
      merchant.value.latitude = val.lat
      merchant.value.lngAndLat = val.lngAndLat
    }

    // 重置
    const resetForm = () => {
      form.value.resetFields()
      initLogo.value = []
      initImgs.value = []
    }

    // 返回
    const back = () => {
      resetForm()
      router.go(-1)
    }

    onMounted(async () => {
      getMerCateListAll()
      if (route.query.id) {
        isAdd.value = false
        getDetail(route.query.id)
      } else {
        resetForm()
        initLogo.value = []
        initImgs.value = []
        isAdd.value = true
      }
    })

    return {
      route,
      router,
      store,
      form,
      isAdd,
      merchant,
      rules,
      imgRules,
      logoRules,
      initLogo,
      initImgs,
      cateList,
      drawer,
      back,
      submitForm,
      openMap,
      resetForm,
      getDetail,
      getRules,
      addressConfirm,
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  .title {
    height: 59px;
    line-height: 59px;
    color: $parent-title-color;
    font-size: 16px;
    font-weight: 500;
    text-indent: 40px;
    border-bottom: 1px solid #dae1ec;

    .back {
      float: right;
      margin-right: 40px;
      cursor: pointer;
    }
  }

  .wrap {
    padding: 20px;
  }

  .submit {
    float: left;
  }
}
.full-input {
  &.el-select {
    width: 100% !important;
  }
}
::v-deep(.el-input-group__append),
::v-deep(.el-input-group__prepend) {
  background: #fff;
  color: #333333;
  border: none;
}
::v-deep(.el-cascader),
::v-deep(.el-range-editor.el-input__inner),
::v-deep(.el-date-editor.el-input, .el-date-editor.el-input__inner) {
  width: 100% !important;
}
::v-deep(.el-drawer) {
  width: 70% !important;
}
</style>
